import React from "react";
import '../Style/Thankyou.css';
import Footer from "./Footer";

const SubscribeThankyou = () =>{


    return(

        <>
        <header class="site-header" id="header">
		<i class="fa fa-check main-content__checkmark" id="checkmark"></i>
		<h1 class="site-header__title" data-lead-id="site-header-title">THANK YOU! </h1>
	</header>

	<div class="main-content">
		<p class="main-content__body">Thank you for subscribing to the News Letter. You will receive updates on your registered email. If you need any support you can always write to us at info@clearrmynotice.com . Thank you.</p>
	
    </div>



        <Footer />
        </>

    );
}
export default SubscribeThankyou;