import React, { useEffect } from "react";
import '../Style/TeamExperience.css'
import Footer from "./Footer";
import infosys from '../TeamExpLogo/infosys.png';
import akCapital from '../TeamExpLogo/akCapital.png';
import eihLtd from '../TeamExpLogo/eihLtd.png';
import global from '../TeamExpLogo/global.png';
import glottis from '../TeamExpLogo/glottis.png';
import godrej from '../TeamExpLogo/godrej.png';
import hero from '../TeamExpLogo/hero.png';
import ihcl from '../TeamExpLogo/ihcl.png';
import ison from '../TeamExpLogo/ison.png';
import jSimple from '../TeamExpLogo/jSimple.png';
import lloyd from '../TeamExpLogo/lloyd.png';
import max from '../TeamExpLogo/max.png';
import nestle from '../TeamExpLogo/nestle.png';
import nissan from '../TeamExpLogo/nissan.png';
import pepsi from '../TeamExpLogo/pepsi.png';
import roadBuilding from '../TeamExpLogo/roadBuilding.png';
import samsung from '../TeamExpLogo/samsung.png';
import si21 from '../TeamExpLogo/si21.png';
import tata from '../TeamExpLogo/tata.png';
import tcns from '../TeamExpLogo/tcns.png';
import trl from '../TeamExpLogo/trl.png';
import unicharm from '../TeamExpLogo/unicharm.png';
import wipro from '../TeamExpLogo/wipro.png';


const TeamExperience = () =>{
    useEffect(() => {
        window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
     })

    return (
        <div className="te_parent text-center">

            <div class="container-fluid">
            <h2>Our Team Experiences</h2>
          
    
    <ul class="cards">
        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={infosys} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Infosys</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={glottis} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Glottis Global Logistics</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={roadBuilding} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Road Building International Group</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={tcns} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">TCNS Clothing Company Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={global} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Global Apparel Solutions Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={jSimple} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">J Simple LLC</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={ison} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">iSON Group</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={si21} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Si2i Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={pepsi} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">PEPSI</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={nestle} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Nestle India Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={nissan} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Nissan Motor India Private Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={akCapital} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">A.K. Capital Services Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={eihLtd} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">EIH Limited (Oberoi and Trident)</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={ihcl} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Indian Hotels Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={trl} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">TATA Refractories Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={tata} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">TATA Chemicals Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={wipro} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Wipro E-Peripherals Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={samsung} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Samsung Group Companies</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={hero} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Hero Group Companies</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={godrej} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Godrej Group Companies</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={max} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Max Hospital Group Companies</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={lloyd} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Lloyd Electrical and Engineering Limited</a>
            </div>
        </li>

        <li class="card t-card">
            <div>
                <div class="card-content">
                 <img src={unicharm} alt="1"/>
                </div>
            </div>
            <div class="card-link-wrapper">
                <a href="#!" class="card-link">Unicharm India Private Limited</a>
            </div>
        </li>        

    </ul>

</div>

            <Footer />
        </div>
    );
}
export default TeamExperience;