import React from "react";
import companyLogo from '../Resources/companyLogo.png';
import '../Style/Navbar.css';
import { Link } from "react-router-dom";

const Navbar = ()=>{

    return (
<>
<nav class="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: "#0B406A"}}>
  <div class="container-fluid py-0">
  <a class="navbar-brand fs-3" href="#!">
  <Link to="/"><img src={companyLogo} alt="Logo" width="20" height="70" class="d-inline-block align-text-top brand_img" />
      <span className="nav_item_stuff_brand">CLEARR </span>
      <blockquote>MYNOTICE</blockquote>
      <blockquote className="navbar_logo_tagline"><i>(By AKKD Services)</i></blockquote>

      
      </Link>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">


      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar_stuff_parent">
      
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#!">
          <Link to="/about" className="nav_item_stuff">About Us</Link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#!">
          <Link to="/service" className="nav_item_stuff">Our Services</Link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#!">
          <Link to="/team" className="nav_item_stuff">Our Associates</Link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#!">
          <Link to="/team_experience" className="nav_item_stuff">Team Experience</Link>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#!">
          <Link to="/contact" className="nav_item_stuff">Contact Us</Link>
          </a>
        </li>
        <div className="d-flex">
        <li class="nav-item">
          <a class="nav-link book-btn" aria-current="page" href="#!">
          <Link to="/team" className="nav_item_stuff">BOOK NOW</Link>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link login_btn nav_item_stuff text-dark" aria-current="page" href="https://clearrmynotice.azurewebsites.net">LOGIN</a>
        </li>
        </div>
       
      </ul>


    </div>
  </div>
</nav>
</>

    );
};

export default Navbar;
