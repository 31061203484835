import React, { useEffect } from 'react'
import '../Style/FAQ.css';
import Footer from './Footer';

const FAQ = ()=>{

  useEffect(() => {
    window.scrollTo({
           top: 0,
           left: 0,
           behavior: "smooth"
         });
 })

    return (
        <>
<section className='faq-parent'>
  <div>
    <div>
      <div>
        <h2 class="display-5 text-center">Frequently Asked Questions</h2>
      </div>
    </div>
  </div>

  <div class="">
    <div class="">
      <div class="">
        <div class="">
          <div class="accordion accordion-flush" id="faqAccount">
            <div class="accordion-item bg-transparent border-top border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading1">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse1" aria-expanded="false" aria-controls="faqAccountCollapse1">
                What services does AKKD SERVICES offer?
                </button>
              </h2>
              <div id="faqAccountCollapse1" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading1">
                <div class="accordion-body">
                  <p>We offer an entire range of Financial and
Business Consultancy Services tailored to meet the diverse needs of individuals and
businesses. Our services include Financial Planning, Investment Management, Tax
Management &amp; Planning, Business Management and Advisory including and not limited to
incubation and start-up Services and more.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading2">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse2" aria-expanded="false" aria-controls="faqAccountCollapse2">
                How can Financial &amp; Business Consultancy help me?
                </button>
              </h2>
              <div id="faqAccountCollapse2" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading2">
                <div class="accordion-body">
                  <p>Financial &amp; Business Consultancy can
help you achieve your Financial &amp; Business goals by providing personalized advice and
strategies to optimize your Finances and Business Operations &amp; Management. Whether
you&#39;re planning for restructuring your Company’s Finances or looking to expand your
Business or just looking for expert guidance and support to address any Taxation or
Compliance issue we can assist you in every step of the way.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading3">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse3" aria-expanded="false" aria-controls="faqAccountCollapse3">
                Who can benefit from our Services?
                </button>
              </h2>
              <div id="faqAccountCollapse3" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading3">
                <div class="accordion-body">
                  <p>Our services are designed to benefit individuals,
families, businesses, and organizations of all sizes. Whether you&#39;re just starting out on your
Financial or Business journey or seeking advanced Financial and Business management
solutions, we can help you navigate the complexities of Finance &amp; Business management
and give you the space to focus on things that are important to you.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading4">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse4" aria-expanded="false" aria-controls="faqAccountCollapse4">
                How do I get started with availing our Consultancy Services?
                </button>
              </h2>
              <div id="faqAccountCollapse4" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading4">
                <div class="accordion-body">
                  <p>Getting started with our
Consultancy Services is easy. Simply contact us to schedule an initial Consultation with any
of the experienced Associates &amp; Business Advisors. The initial Consultation is completely
free. During this meeting, we&#39;ll discuss your Financial &amp; Business goals or understand the
specific issue that you are confronted with, assess your current situation, and develop a
customized plan to help you reach your objectives or just resolve the crisis. We work as an
extension of our Clients.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading5">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse5" aria-expanded="false" aria-controls="faqAccountCollapse5">
                Are our Associates qualified?
                </button>
              </h2>
              <div id="faqAccountCollapse5" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading5">
                <div class="accordion-body">
                  <p>Yes, our Associates come with extensive hands on experience
in the field of Finance and Business advisors. They hold substantial experience of having
engaged and dealt with leading Corporate and Institutional Clients.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading6">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse6" aria-expanded="false" aria-controls="faqAccountCollapse6">
                How do we charge for our Services?
                </button>
              </h2>
              <div id="faqAccountCollapse6" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading6">
                <div class="accordion-body">
                  <p>Our fee structure varies depending on the nature and
complexity of the services provided. We offer transparent pricing and will discuss our fees
with you upfront before any work is commenced. Our goal is to provide value-driven
Services that align with your Financial &amp; Business goals and objectives without being a
burden.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading7">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse7" aria-expanded="false" aria-controls="faqAccountCollapse7">
                Do we offer ongoing support?
                </button>
              </h2>
              <div id="faqAccountCollapse7" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading7">
                <div class="accordion-body">
                  <p>Yes, we believe in building long-term relationships with our
Clients and offer ongoing support and monitoring to ensure that your Financial &amp; Business
plan remains aligned with your goals. We&#39;ll regularly review your plans, make adjustments as
necessary, and keep you informed every step of the way.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading8">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse8" aria-expanded="false" aria-controls="faqAccountCollapse8">
                Is my information kept confidential?
                </button>
              </h2>
              <div id="faqAccountCollapse8" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading8">
                <div class="accordion-body">
                  <p>Yes, we take your privacy and confidentiality seriously.
We adhere to strict Privacy Policies and Procedures to safeguard your Personal, Financial &amp;
Business information. Rest assured that your information will be kept Confidential and used
only for the purpose of providing our Consultancy Services.</p>
                </div>
              </div>
            </div>


            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading9">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse9" aria-expanded="false" aria-controls="faqAccountCollapse9">
                Do you provide educational resources or workshops?
                </button>
              </h2>
              <div id="faqAccountCollapse9" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading9">
                <div class="accordion-body">
                  <p>Yes, we offer educational resources,
workshops, and seminars to help empower our clients with the knowledge and skills they
need to make informed Financial &amp; Business decisions. Subscription to our Newsletter will
keep you updated of all the industry and Policy changes that are taking place on a real time
basis. You can also access our library to enhance your Financial &amp; Business literacy related to
critical topics.</p>
                </div>
              </div>
            </div>


            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAccountHeading10">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAccountCollapse10" aria-expanded="false" aria-controls="faqAccountCollapse10">
                How can I contact you for further inquiries?
                </button>
              </h2>
              <div id="faqAccountCollapse10" class="accordion-collapse collapse" aria-labelledby="faqAccountHeading10">
                <div class="accordion-body">
                  <p>If you have any further questions or inquiries,
please don&#39;t hesitate to contact us. You can reach us by sending us an enquiry or booking a direct consultation through the links on our website www.clearrmynotice.com. Our friendly
team will be there to assist you at the earliest and address any concerns that you may have.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</section>
<Footer />
        </>


    )
}

export default FAQ;