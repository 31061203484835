import React, { useEffect, useState } from "react";
import Footer from '../Components/Footer'
import '../Style/Contact.css'
// import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";

const Contact = ()=>{
	useEffect(() => {
        window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
     })

	 const [formData, setFormData] = useState({
		u_name: '',
		u_email: '',
		u_phone:'',
		u_subject: '',
		u_message:'',
	});
	
	const handleChange = (e) => {
	  setFormData({
		  ...formData,
		  [e.target.name]: e.target.value,
	  });
	};
	const navigate = useNavigate();
	const handleSubmit = (e) => {
	  e.preventDefault();
	  emailjs.sendForm('service_ehzrsei', 'template_4grmg0a', e.target, 'lD7x7pBmOWWZHfXGv')
		  .then((result) => {
			//   alert('Email sent successfully: ' + result.text);
			  navigate('/thankyou')
		  }, (error) => {
			  console.error('Error sending email:', error.text);
			  alert('Error sending email: ' + error.text);
		  });
	};
	
	

    return(
        <div className="servicePage">
        <div id="contact" class="contact-area section-padding">
	<div class="container">										
		<div class="section-title">
			<h1>Get in Touch</h1>
			{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae risus nec dui venenatis dignissim. Aenean vitae metus in augue pretium ultrices.</p> */}
		</div>					
		<div class="row">
			<div class="col-lg-7">	
				<div class="contact">
					<form class="form" name="enq" onSubmit={handleSubmit}>
						<div class="row">
							<div class="form-group col-md-6">
								<input type="text" id="u_name" name="u_name"value={formData.u_name} onChange={handleChange} class="form-control" placeholder="Name" required/>
							</div>
							<div class="form-group col-md-6">
								<input type="email" id="u_email" name="u_email" value={formData.u_email} onChange={handleChange} class="form-control" placeholder="Email" required/>
							</div>
							<div class="form-group col-md-12">
								<input type="number" id="u_phone" name="u_phone" value={formData.u_phone} onChange={handleChange} class="form-control" placeholder="Phone Number" required/>
							</div>
							<div class="form-group col-md-12">
								<input type="text" id="u_subject" name="u_subject" value={formData.u_subject} onChange={handleChange} class="form-control" placeholder="Subject" required/>
							</div>
							<div class="form-group col-md-12">
								<textarea rows="6" id="u_message" name="u_message"value={formData.u_message} onChange={handleChange} class="form-control" placeholder="Your Message" required></textarea>
							</div>
							<div class="col-md-12 text-center">
								<button type="submit" value="Send message" name="submit" id="submitButton" class="btn btn-contact-bg" title="Submit Your Message!">Send Message</button>
							</div>
						</div>
					</form>
				</div>
			</div> 
			<div class="col-lg-5">
				<div class="single_address">
					<i class="fa fa-map-marker"></i>
					<h4 >INDIA</h4>
					<div class="dropdown">
  <button class="btn dropdown-toggle fs-1 text-danger" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    
  </button>
  <ul class="dropdown-menu">
    <li className="p-3" ><b>NEW DELHI</b> <br/> BP-1, First Floor, Shalimar Bagh, Delhi, 110088</li>
    <li className="p-3" ><b>GURGAON</b> <br/> 120, Vipul Business Park, Sector 48, Sohna Road, Gurgaon 122018</li>
    <li className="p-3" ><b>MUMBAI</b> <br/>Kanakia Wall Street, B Wing, 10th Floor, 1011, Andheri Kurla Road, Andheri East, Mumbai 400093</li>
	<li className="p-3" ><b>HYDERABAD</b> <br />HOUSE NO 2-134-1 Kellampalli post, Marripudi mandal Prakasam district, Andhra Pradesh pincode 523240</li>
	<li className="p-3" ><b>CHENNAI</b> <br/> House no 28 Indranagar First Line, Mannapakam main road, Chennai Pincode: 600125</li>
  </ul>
</div>
				</div>
				<div class="single_address_international">
					<i class="fa fa-map-marker"></i>
					<h4>INTERNATIONAL</h4>
					<div class="dropdown">
  <button class="btn dropdown-toggle fs-1 text-danger" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    
  </button>
  <ul class="dropdown-menu">
    <li className="p-3" ><b>DUBAI</b> <br/> UNIT 304, Al Khor Building, Baniyas Road , Al, Dubai</li>
  </ul>
</div>
				</div>
				<div class="single_address_mail">
					<i class="fa fa-envelope"></i>
					<h4>Email</h4>
					<p>info@clearrmynotice.com</p>
				</div>
				<div class="single_address_call">
					<i class="fa fa-phone"></i>
					<h4>Call / Whatsapp</h4>
					<p><a className="text-black" href='https://api.whatsapp.com/send?phone=918376060101'>(+91) 837-606-0101</a></p>
				</div>
				{/* <div class="single_address">
					<i class="fa fa-clock-o"></i>
					<h4>Work Time</h4>
					<p>Mon - Fri: 08.00 - 16.00. <br/>Sat: 10.00 - 14.00</p>
				</div>					 */}
			</div> 
		</div> 
	</div>	
</div>

        <Footer/>
        </div>
    )
};

export default Contact;