import React from "react";
import '../Style/Thankyou.css';
import Footer from "./Footer";

const Thankyou = () =>{


    return(

        <>
        <header class="site-header" id="header">
		<i class="fa fa-check main-content__checkmark" id="checkmark"></i>
		<h1 class="site-header__title" data-lead-id="site-header-title">THANK YOU! </h1>
	</header>

	<div class="main-content">
		<p class="main-content__body">Thank you for submitting the query. We will revert back to you shortly. Best regards.</p>
	
    </div>



        <Footer />
        </>

    );
}
export default Thankyou;