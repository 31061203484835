import React, { useEffect } from 'react';
import '../Style/About.css'
import Footer from '../Components/Footer'
import HomeBanner from '../Components/HomeBanner'
// import FAQ from '../Components/FAQ'
const About = ()=>{

    useEffect(() => {
        window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
     })
    return (
        <div className='parent'>
             <div class="about-section">
        <div class="about-parent">

        <h1>THE FIRM <br/><span className='fs-5'>Your Premier Destination for Taxation, Financial Management, and
            Expert Business Advisory Services.</span></h1>
         <p>
            Navigating the complexities of Taxation, Financial and Business Management can be daunting, but
with the right guidance, you can unlock new opportunities for growth and prosperity. At AKKD
SERVICES, we specialize in providing comprehensive solutions tailored to meet your individual and
business needs, empowering you to make informed decisions and achieve your financial goals and
allowing you to focus more on the growth of your business and quality of personal life.
         </p>

        </div>

        <div class="about-parent-2">
            <div class="mission">
                <h1>MISSION</h1>
                <p>
                    " To empower individuals and businesses with comprehensive taxation expertise
and strategic financial management solutions, fostering financial stability, growth, and prosperity of the businesses for
our clients "
                </p>
            </div>
            <div class="vision">
                <h1>VISION</h1>
                <p>
                " Our vision is to become the trusted partner of choice for individuals and
businesses seeking expert guidance in taxation, financial & business management, and advisory services. We
aspire to uphold the highest standards of integrity, professionalism, and client-centricity, making a
meaningful impact on the financial success and well-being of our clients and communities "
                </p>
            </div>
        </div>
    </div>


    {/* <div class="home-banner">
        <p>READY TO TAKE NEXT STEP?</p>

        <blockquote>
            Schedule a Consultation with one of our Experienced Professional today or send us an enquiry to discuss your unique
needs and explore how we can help you achieve your financial objectives. Let us be your trusted
partner on the path to your financial success.
        </blockquote>
       
        <div class="home-banner-buttons">
            <button type="button" class="btn">BOOK NOW</button>
            <Link to='/enquiry'><button type="button" class="btn">SEND ENQUIRY</button></Link>
        </div>
    </div> */}

    <HomeBanner />

    {/* <FAQ /> */}

    <Footer />
        </div>
    )
};

export default About;