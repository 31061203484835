import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyATWyBbnSRH8etn7V4VBhpp0eaoJRTPaVg",
  authDomain: "clearrmynotice-698e7.firebaseapp.com",
  projectId: "clearrmynotice-698e7",
  storageBucket: "clearrmynotice-698e7.appspot.com",
  messagingSenderId: "298714609627",
  appId: "1:298714609627:web:f17a67ae686ec7864a19fe",
  measurementId: "G-KDGYEQNX7S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage, app, analytics };