import React, { useEffect } from 'react';
import '../Style/Home.css';
import Footer from '../Components/Footer'
import HomeBanner from '../Components/HomeBanner'
import { Link } from 'react-router-dom';
// import FAQ from './FAQ';
// import { Link } from 'react-router-dom';

const Home = ()=>{

    useEffect(() => {
        window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
     })

    return (

        <>
        <div class="banner"> 

        <div class="banner-parent">

            <div class="banner-child-1">
                <h4>FINANCIAL ADVISORY & MANANGEMENT SERVICES </h4>
                <ul>
                    <Link to="/service" className='text-light'><li>Budgeting &amp; Forecasting / Cash flow Management /</li></Link>
                    <Link to="/service" className='text-light'><li>Investment Planning / Debt Management /</li></Link>
                    <Link to="/service" className='text-light'><li>Financial Analysis and Process Reporting / Forensic Audit</li></Link>
                    <Link to="/service" className='text-light'><li>Risk Mitigation and Management / Tax Planning &amp; Compliances /</li></Link>
                    <Link to="/service" className='text-light'><li>Business Stock Listing &amp; Initial Public Offering (IPO) /</li></Link>
                    <Link to="/service" className='text-light'><li>Business Valuation / Tax Education and Training /</li></Link>
                </ul>

            </div>
            <div class="banner-child-2">
                <h4>BUSINESS ADVISORY <br/>SERVICES</h4>
                <ul>
                <Link to="/service" className='text-light'> <li >Startup Incubation Service</li></Link>
                <Link to="/service" className='text-light'>  <li >Business &amp; Project Consultancy Services</li></Link>
                </ul>

            </div>
         </div>
        
        </div>


        <div class="about-section">
        <div class="about-parent">

        <h1>THE FIRM</h1>
         <h3><blockquote>Your Premier Destination for Taxation, Financial Management, and
            Expert Business Advisory Services.</blockquote></h3>
         <p>
            Navigating the complexities of Taxation, Financial and Business Management can be daunting, but
with the right guidance, you can unlock new opportunities for growth and prosperity. At AKKD
SERVICES, we specialize in providing comprehensive solutions tailored to meet your individual and
business needs, empowering you to make informed decisions and achieve your financial goals and
allowing you to focus more on the growth of your business and quality of personal life.
         </p>

        </div>

        <div class="about-parent-2">
            <div class="mission">
                <h1>MISSION</h1>
                <p>
                    " To empower individuals and businesses with comprehensive taxation expertise
and strategic financial management solutions, fostering financial stability, growth, and prosperity of the businesses for
our clients "
                </p>
            </div>
            <div class="vision">
                <h1>VISION</h1>
                <p>
                " Our vision is to become the trusted partner of choice for individuals and
businesses seeking expert guidance in taxation, financial & business management, and advisory services. We
aspire to uphold the highest standards of integrity, professionalism, and client-centricity, making a
meaningful impact on the financial success and well-being of our clients and communities "
                </p>
            </div>
        </div>
    </div>


    <div class="why-choose-section">
        <h1>WHY CHOOSE US?</h1>
        <div class="why-choose-section-parent">
            <ul class="choose-section-list">
                <li>EXPERTISE</li>
                  <p>
                    Our team consists of seasoned professionals with extensive experience in
taxation, financial management, and advisory services. With a deep understanding of ever-
changing tax laws and financial regulations, we offer expert guidance to help you navigate
complex financial landscapes with confidence.
                  </p>
                  <hr/>

                  <li>HOLISTIC APPROACH</li>
                    <p>
                        We believe in taking a holistic approach to financial management,
                        addressing both your short-term needs and long-term aspirations. Whether you&#39;re seeking
                        assistance with tax planning, investment management, retirement planning, or estate
                        planning, we have the expertise to guide you every step of the way.
                    </p>
                    <hr/>

                    <li>CUSTOMIZED SOLUTIONS</li>
                      <p>
                        We understand that every client is unique, which is why we tailor our
                        services to fit your specific circumstances, goals, and objectives. Whether you&#39;re an
                        individual, a small business owner, or a corporate entity, we develop customized solutions
                        designed to optimize your financial outcomes.
                      </p>
                      <hr/>

                      <li>CLIENT-CENTRIC SERVICES</li>
                        <p>
                            At AKKD SERVICES, client satisfaction is our top priority. We take the
                            time to listen to your concerns, answer your questions, and provide personalized attention
                            to ensure that your needs are met and your expectations exceeded.
                        </p>
                        <hr/>
                        <li>COMMITMENT TO EXCELLENCE</li>
                          <p>
                            We are committed to delivering excellence in everything we do.
From providing accurate tax preparation services to offering strategic financial advice, we
uphold the highest standards of professionalism, integrity, and reliability.
                          </p>
                          <hr/>
            </ul>
        </div>

    </div>


    <HomeBanner />
    {/* <FAQ /> */}

    <Footer />
        </>
        
    )
};


export default Home;