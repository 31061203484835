import React from "react";
import { Link } from "react-router-dom";
import '../Style/HomeBanner.css'

const HomeBanner = ()=>{


    return (
        <div class="home-banner">
        <p>READY TO TAKE NEXT STEP?</p>

        <blockquote>
            Schedule a Consultation with one of our Experienced Professional today or send us an enquiry to discuss your unique
needs and explore how we can help you achieve your financial objectives. Let us be your trusted
partner on the path to your <span style={{"textAlign" : "center"}}>financial success.</span>
        </blockquote>
       
        <div class="home-banner-buttons">
            <Link to="/team"><button type="button" class="btn">BOOK NOW</button></Link>
            <Link to="/enquiry"><button type="button" class="btn">SEND ENQUIRY</button></Link>
        </div>
    </div>

    )
};

export default HomeBanner;