import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import About from './Components/About';
import Team from './Components/Team';
import Service from './Components/Service';
import Contact from './Components/Contact';
import Thankyou from './Components/Thankyou';
import Enquiry from './Components/Enquiry';
import TeamExperience from './Components/TeamExperience';
import FAQ from './Components/FAQ';
import PrivacyPolicy from './Components/PrivacyPolicy';
import BookThankyou from './Components/BookThankyou';
import SubscribeThankyou from './Components/SubscribeThankyou'
// import TestNavbar from './Components/TestNavbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/team_experience" element={<TeamExperience />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/thank-you" element={<BookThankyou />} />
        <Route path='/newsletterthankyou' element={<SubscribeThankyou />} />
        
      </Routes>
    </Router>


  </React.StrictMode>,
  
);
reportWebVitals();
