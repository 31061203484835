import React, { useEffect, useState } from 'react';
import '../Style/Enquiry.css'
import emailjs from '@emailjs/browser';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";


const Enquiry = ()=>{

  const [formData, setFormData] = useState({
    u_name: '',
    u_email: '',
    u_phone: '',
    u_message:'',
    file: null,
});

const handleChange = (e) => {
  const { name, value, files } = e.target;
  setFormData({
    ...formData,
    [name]: name === 'file' ? (files ? files[0] : null) : value,
  });
};


const navigate = useNavigate();

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    let fileUrl = null;

    if (formData.file) {
      const fileRef = ref(storage, `files/${formData.file.name}`);
      await uploadBytes(fileRef, formData.file);
      fileUrl = await getDownloadURL(fileRef);
    }

    const templateParams = {
      u_name: formData.u_name,
      u_email: formData.u_email,
      u_phone: formData.u_phone,
      u_message: formData.u_message,
      fileUrl: fileUrl,
    };

    emailjs
      .send(
        'service_66ghhmx',
        'template_bgeplxc',
        templateParams,
        'IUdgUmRc43niA9p88'
      )
      .then((result) => {
        navigate('/thankyou');
      })
      .catch((error) => {
        alert(error.text);
      });
  } catch (error) {
    alert('Error uploading file: ' + error.message);
  }
};





  useEffect(() => {
    window.scrollTo({
           top: 0,
           left: 0,
           behavior: "smooth"
         });
 })
        

    return (
      <>
        <div className="enquiry-parent">

<section class="container-parent">
  <div class="container-fluid w-100">
    <div class="row justify-content-lg-center">
    <h3 className="text-center">Send us an Enquiry</h3>
      <div class="col-12 col-lg-9">
        <div class="bg-white border rounded shadow-sm overflow-hidden">

          <form onSubmit={handleSubmit}>

            <div class="row gy-4  p-4">
              <div class="col-12">
                <label for="u_name" class="form-label">Full Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="u_name" name="u_name" value={formData.u_name} onChange={handleChange} required  />
              </div>
              <div class="col-12 col-md-6">
                <label for="u_email" class="form-label">Email <span class="text-danger">*</span></label>
                <div class="input-group">
                  <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </span>
                  <input type="email" class="form-control" id="u_email" name="u_email" value={formData.u_email} onChange={handleChange} required  />
                </div>
              </div>

              {/*  */}
              <div class="col-12 col-md-6">

                <label for="u_phone" class="form-label">Phone Number</label>
                <div class="input-group">
                  {/* <span class="input-group-text"> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg> */}
                  <select style={{width:'60px', border:'1px solid grey', borderRadius:'5px 0px 0px'}} >
                      <option selected>+91</option>
                      <option value="44">+44</option>
                      <option value="66">+66</option>
                      <option value="97">+97</option>
                      <option value="978">+978</option>
                      <option value="968">+968</option>
                      <option value="1">+1</option>
                      <option value="135">+135</option>
                      <option value="14">+14</option>
                    </select>
                  {/* </span> */}
                  <input type="tel" class="form-control" id="u_phone" name="u_phone" value={formData.u_phone} onChange={handleChange} required  />

                </div>
              </div>

              {/*  */}
              <div class="col-12">
                <label for="message" class="form-label">Please give a brief background / profile & the issue that you wish us to advice on.  <span class="text-danger">*</span></label>
                <textarea class="form-control" id="u_message" name="u_message" value={formData.u_message} onChange={handleChange} required ></textarea>
              </div>
              <div class="col-12">
                <label for="file" class="form-label">Upload attachment (If any)</label>
                <input type="file" class="form-control" id="file" name="file"
                      onChange={handleChange} />
              </div>
              <div class="col-20 text-center">
                <button className="btn enquiry-btn" type="submit">Submit</button>
          
              </div>
            </div>
            
          </form>

        </div>
      </div>
    </div>
  </div>
</section>

        </div>


<Footer />
</>
    );
}

export default Enquiry;