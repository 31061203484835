import React, {useState } from "react";
import companyLogo from '../Resources/companyLogo.png';
import '../Style/Footer.css';
import { Link, useNavigate } from "react-router-dom";

import emailjs from '@emailjs/browser';
// import { Link } from "react-router-dom";

const Footer = ()=>{

const [formData, setFormData] = useState({
u_email: '',
});

const handleChange = (e) => {
setFormData({
  ...formData,
  [e.target.name]: e.target.value,
});
};
const navigate = useNavigate();

const handleSubmit = (e) => {
e.preventDefault();
emailjs.sendForm('service_14pna5t', 'template_09t2ovk', e.target, 'HhHVD3Vko1N4TB7z4')
  .then((result) => {
    // alert('Email sent successfully: ' + result.text);
    navigate('/newsletterthankyou')
  }, (error) => {
    console.error('Error sending email:', error.text);
    alert('Error sending email: ' + error.text);
  });
};




    return (
<footer class="text-center text-white text-lg-start footer">
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    <div class="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>

    <div>
      <a href="https://www.instagram.com/clearrmynotice" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="https://www.linkedin.com/company/clearrmynotice" class="me-4 text-reset">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="https://www.facebook.com/share/tezMsZQH2wko3UVt/?mibextid=qi2Omg" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="https://t.me/cmnedu" class="me-4 text-reset">
        <i class="fab fa-telegram"></i>
      </a>
      <a href="mailto:info@clearmynotice.com" class="me-4 text-reset">
        <i class="fab fa-google"></i>
      </a>
    </div>
  </section>

  <section>
  <div class="container text-md-start">
      <div class="row mt-2">
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
          <img href='#' className="footer_logo" src={companyLogo} alt="logo" width="90" height="70"/>
          <p className="footer_item_stuff_brand">CLEARR</p>
          <p className="footer_logo_desc">MYNOTICE</p>
          <p className="footer_logo_desc2"><i>(By AKKD Services)</i></p>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 mb-4">
          <p><Link to="/about" class="footer_Stuff">About Us</Link></p>
          <p><Link to="/service" class="footer_Stuff">Our Services</Link></p>
          <p><Link to="/team" class="footer_Stuff">Our Associates</Link></p>
        </div>

        <div class="col-md-3 col-lg-2 col-xl-2 mb-4">
          <p><Link to="/contact" class="footer_Stuff">Contact Us</Link></p>
          <p><Link to="/team" class="footer_Stuff">Book an Appointment</Link></p>
          <p><Link to="/team_experience" class="footer_Stuff">Team Experience</Link></p>
        </div>

        <div class="col-md-3 col-lg-2 col-xl-2 mb-4">
          <p><Link to="/" class="footer_Stuff">Blog</Link></p>
          <p><Link to="/faq" class="footer_Stuff">FAQs</Link></p>
          <p><Link to="/privacy-policy" class="footer_Stuff">Terms of Service Offering</Link></p>
        </div>
      </div>
    </div>
  </section>

  <section class="newsletter-section">
    <form onSubmit={handleSubmit}>
      <div class="row d-flex justify-content-center">
        <div class="col-auto">
          <p class="pt-2">
            <strong>Sign up for our newsletter</strong>
          </p>
        </div>
        <div class="col-md-5 col-12">
          <div class="form-outline mb-4">
            <input type="email" id="form5Example24" class="form-control" name="u_email" value={formData.u_email} onChange={handleChange} placeholder="Enter Your Email Address" required/>
          </div>
        </div>
        <div class="col-auto">
          <button type="submit" name="submit" class="btn btn-primary mb-4">Subscribe</button>
        </div>
      </div>
    </form>
  </section>

  <div class="text-center p-2" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
    &copy; 2024 AKKD Services. All rights reserved.
    <a class="text-reset fw-bold" href="https://clearrmynotice.com/">clearrmynotice.com</a>
  </div>
</footer>


    );
};

export default Footer;