import React, { useEffect } from "react";
import "../Style/PrivacyPolicy.css"
import { Link } from "react-router-dom";

const PrivacyPolicy = ()=>{

        useEffect(() => {
                window.scrollTo({
                       top: 0,
                       left: 0,
                       behavior: "smooth"
                     });
             })

    return(
        <div className="p-parent">

        <div class="bg-gray-100">
        <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4">Terms of Service Offering</h1>

        <p class="mb-4">
        Welcome to <a href="www.clearrmynotice.com">CLEARRMYNOTICE.COM.</a> These Terms Of Service Offering 
        <a href="www.clearrmynotice.com">WWW.CLEARRMYNOTICE.COM</a>
(&quot;Terms&quot;) govern your use of our website, located at (&quot;The Website&quot;), and any Services provided
through the Website.<br/><br />
<b><i>By accessing or using this Website, you are agreeing to these Terms of Use. If you
do not agree to these Terms of Use, then you are not allowed to use this Website and
should immediately terminate such usage.</i></b>
</p>

        <ol>
                <li class="text-2xl font-bold mb-2"> <h2><u>Use of the Website</u></h2></li>
                 <ul>
                        <li className="mb-4"><b>Eligibility:</b> By using the Website, you represent and warrant that you are at least
                        18 years of age and have the legal capacity to enter into these Terms.</li>

                        <li className="mb-4"><b>License:</b>We grant you a limited, non-exclusive, non-transferable, and revocable
license to access and use the Website for personal or business purposes in accordance
with these Terms.</li>

<li className="mb-4"><b>Prohibited Uses:</b>You agree not to - </li>
<ul>
        <li>Use the Website for any unlawful purpose or in violation of any applicable laws.</li>
        <li>Engage in any activity that interferes with or disrupts the Website.</li>
        <li>Attempt to gain unauthorized access to any portion of the Website.</li>
        <li>Use any automated means to access or use the Website.</li>
</ul>

                 </ul>

                 <li class="text-2xl font-bold mt-3 mb-2"> <h2><u>Services</u></h2></li>
                 <ul>
                        <li className="mb-4"><b>Consultancy Services: </b>The Website may offer consultancy services (&quot;Services&quot;).
These Services are subject to separate agreements and terms, which will be provided
to you before engaging our consultancy services.</li>

<li className="mb-4"><b>Service Availability: </b>We reserve the right to modify or discontinue any Services
at any time without notice.</li>

                 </ul>

                 <li class="text-2xl font-bold mt-3 mb-2"> <h2><u>Intellectual Property Rights</u></h2></li>
                 <ul>
                        <li className="mb-4"><b>Ownership:</b> All content, features, and functionality on the Website, including
text, graphics, logos, and software, are the exclusive property of M/S AKKD
SERVICES or its licensors and are protected by copyright, trademark, patent, trade
secret, and other intellectual property laws.</li>

<li className="mb-4"><b>Trademarks:</b> All trademarks, service marks, and trade names are proprietary
to M/S AKKD SERVICES or its affiliates.</li>
                 </ul>

                 <li class="text-2xl font-bold mt-3 mb-2"> <h2><u>User Content</u></h2></li>
                 <ul>
                        <li className="mb-4"><b>User Contributions:</b> You may submit content to the Website, including
documents referring to your need for Consultation, feedback, comments, and

suggestions (&quot;User Content&quot;). By submitting User Content, you grant us a non-
exclusive, perpetual, and right to study, use and analyse the same. Other than
information that is of Confidential nature we will hold the right to reproduce, modify,
and distribute your User Content.</li>

<li className="mb-4"><b>Content Standards:</b> User Content must not - </li>
 <ul>
        <li>Violate any applicable laws or regulations.</li>
        <li>Infringe any intellectual property or other rights of any third party.</li>
        <li>Be defamatory, obscene, offensive, or otherwise inappropriate.</li>
 </ul>
                 </ul>


        </ol>


       
    </div>



    <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-4"><small className="fs-6">5.</small><u>Privacy Policy</u></h1>

        <p class="mb-4">
        AKKD SERVICES operates <a href="www.clearrmynotice.com">www.clearrmynotice.com</a> (the &quot;Site&quot;). This page informs you of our policies
regarding the collection, use, and disclosure of Personal Information we receive from users of the
Site. <br/> We use your Personal Information only for providing and improving the Site. By using the Site, you
agree to the collection and use of information in accordance with this policy.
        </p>

        <h2 class="text-2xl font-bold mb-2"><small className="fs-6">5.1.</small>Information Collection and Use</h2>

        <p class="mb-4">
        While using our Site, we may ask you to provide us with certain personally identifiable
information that can be used to contact or identify you. Personally identifiable
information may include but is not limited to your name (&quot;Personal Information&quot;)..
        </p>

        <h2 class="text-2xl font-bold mb-2"><small className="fs-6">5.2.</small>Log Data</h2>

        <p class="mb-4">
        Like many site operators, we collect information that your browser sends whenever you
visit our Site (&quot;Log Data&quot;). This Log Data may include information such as your
computer&#39;s Internet Protocol (&quot;IP&quot;) address, browser type, browser version, the pages of
our Site that you visit, the time and date of your visit, the time spent on those pages, and
other statistics.
        </p>

        <h2 class="text-2xl font-bold mb-2"><small className="fs-6">5.3.</small>Cookies</h2>

        <p class="mb-4">
        Cookies are files with a small amount of data, which may include an anonymous unique
identifier. Cookies are sent to your browser from a web site and stored on your
computer&#39;s hard drive.
Like many sites, we use &quot;cookies&quot; to collect information. You can instruct your browser
to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
accept cookies, you may not be able to use some portions of our Site.
        </p>

        <h2 class="text-2xl font-bold mb-2"><small className="fs-6">5.4.</small>Security</h2>

        <p class="mb-4">
        The security of your Personal Information is important to us, but remember that no
method of transmission over the Internet, or method of electronic storage, is 100%
secure. While we strive to use commercially acceptable means to protect your Personal
Information, we cannot guarantee its absolute security.
        </p>

        <h2 class="text-2xl font-bold mb-2"><small className="fs-6">5.5.</small>Changes to This Privacy Policy</h2>

        <p class="mb-4">
        This Privacy Policy is effective as of [Date] and will remain in effect except with respect to any
changes in its provisions in the future, which will be in effect immediately after being posted on this
page.
        </p>
        <p class="mb-4">
        We reserve the right to update or change our Privacy Policy at any time and you should check this
Privacy Policy periodically. Your continued use of the Service after we post any modifications to the
Privacy Policy on this page will constitute your acknowledgment of the modifications and your
consent to abide and be bound by the modified Privacy Policy.
        </p>
        <p class="mb-4">
        If we make any material changes to this Privacy Policy, we will notify you either through the email
address you have provided us, or by placing a prominent notice on our website.
        </p>

        <h2 class="text-2xl font-bold mb-2"><small className="fs-6">5.6.</small>Contact Us</h2>

        <p class="mb-4">
        If you have any questions about this Privacy Policy, please <Link to="/contact">Contact Us.</Link> us at <a href="mailto:INFO@CLEARRMYNOTICE.COM">INFO@CLEARRMYNOTICE.COM</a>
        </p>

        <h2 class="text-2xl font-bold mb-4"><small className="fs-6">6.</small><u>Disclaimers</u></h2>
        <ul>
                <li className="mb-4"><b>No Warranties:</b> The Website and Services are provided on an &quot;as is&quot; and &quot;as
available&quot; basis. We make no warranties, express or implied, regarding the Website or
Services, including but not limited to warranties of merchantability, fitness for a
particular purpose, or non- infringement.</li>

<li className="mb-2"><b>Limitation of Liability - </b></li>
 <ul>
        <li>To the fullest extent permitted by law, M/S AKKD SERVICES shall not be
liable for any indirect, incidental, special, or consequential damages arising
out of or in connection with your use of the Website or Services.</li>

<li>Our consultancy services are designed to assist you in preparing and filing
replies to authorities. While we strive to provide comprehensive and
accurate guidance, we do not guarantee that your notice will be resolved or
cleared. The final decision rests with the relevant authorities, and outcomes
may vary based on individual circumstances</li>
 </ul>


         </ul>
         <h2 class="text-2xl font-bold mb-2"><small className="fs-6">7.</small><u>Indemnification</u></h2>
         <p className="mb-4">
         You agree to indemnify, defend, and hold harmless M/S AKKD SERVICES, its
partners, affiliates, and their respective officers, directors, employees, and agents from
and against any claims, liabilities, damages, losses, and expenses arising out of or in
connection with your use of the Website or Services, or your violation of these Terms.
         </p>

         <h2 class="text-2xl font-bold mb-2"><small className="fs-6">8.</small><u> Governing Law</u></h2>
         <p className="mb-4">
         These Terms are governed by and construed in accordance with the laws of INDIA,
without regard to its conflict of law principles. You agree to submit to the exclusive
jurisdiction of the courts located in NEW DELHI to resolve any dispute arising out of
or in connection with these Terms or the Website.
         </p>

         <h2 class="text-2xl font-bold mb-2"><small className="fs-6">9.</small><u>Changes to Terms</u></h2>
         <p className="mb-4">
         We may update these Terms from time to time. Any changes will be posted on this
page with an updated effective date. Your continued use of the Website after any
changes constitutes your acceptance of the new Terms.
         </p>

         <h2 class="text-2xl font-bold mb-2"><small className="fs-6">10.</small><u>Contact Information</u></h2>
         <p className="mb-4">
         If you have any questions or concerns about these Terms, please contact us at: 
         <br/>
         M/S AKKD SERVICES <br />
         EMAIL ID: <a href="mailto:INFO@CLEARRMYNOTICE.COM">INFO@CLEARRMYNOTICE.COM</a>
         </p>

    </div>
</div>


        </div>
    )

};

export default PrivacyPolicy;