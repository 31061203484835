import React, { useEffect } from "react";
import '../Style/Service.css'
import HomeBanner from '../Components/HomeBanner'
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";

const Service = ()=>{

    useEffect(() => {
        window.scrollTo({
               top: 0,
               left: 0,
               behavior: "smooth"
             });
     })

    return(
        <div className="servicePage ">

{/* second services */}

<div class="container">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="section-title mb-4 pb-2">
            <h3 class="fs-2 text-secondary mb-3 mb-xl-4 text-uppercase">What We Do?</h3>
                <h2 class="display-7 fw-bold mb-4">Financial Advisory and Management Services</h2>
                <h6 class="text-muted para-desc mx-auto mb-0">Under the Umbrella of Financial Management Services AKKD SERVICES offers a wide array of
Professional Services designed to help individuals, businesses, and organizations effectively manage
their financial resources, make informed financial decisions, and achieve their financial goals:</h6>
            </div>
        </div>
    </div>

    <div class="row">

    <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Tax Planning & Compliances</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Under Tax Planning &amp; Compliances the consultancy offers
expert advice and assistance across the entire gamut of Tax Related Services. We assist in resolving notices recieved from various authorities under the Income Tax Act 1961, Goods and Services Tax Act, Benami Transactions (Prohibitions) Act, The Black Money Act, Foreign Exchange Money Act (FEMA), Prevention of Money Laundary act (PMLA).</p>
                    <div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Financial Planning</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Financial planners help individuals and businesses develop
comprehensive financial plans tailored to their specific goals and circumstances. This
includes retirement planning, investment planning, risk management, estate planning, and
tax planning.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Business Valuation</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Financial management professionals may provide business valuation
services to help clients determine the value of their business for various purposes, such as
mergers and acquisitions, financial reporting, estate planning, and shareholder transactions.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
               
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Budgeting and Forecasting</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Financial management services include assisting clients in
creating budgets and forecasts to plan and allocate financial resources effectively. This
involves analyzing past financial performance, identifying trends, and projecting future
financial outcomes.</p>

<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
            </div>
        </div>
       


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Debt Management</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Debt management services assist clients in managing and optimizing
their debt obligations, including loans, mortgages, and lines of credit. This involves debt
restructuring, refinancing, and developing strategies to minimize interest costs and improve
debt repayment efficiency.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Financial And Process Audit & Reporting</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Financial management professionals provide
financial analysis and reporting services to help clients understand their financial
performance, identify areas for improvement, and make informed decisions. This includes
financial statement analysis, ratio analysis, and variance analysis.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
               
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Forensic Audit</h5>
                    <p class="text-muted mt-3 mb-0 service-p">We provide specialized forensic services aimed at ensuring integrity through
expert forensic audits. Our experience encompasses investigations conducted
for investors, lenders, and other creditors. We possess a highly skilled team
with substantial expertise in forensic auditing and investigations. We aim at
delivering in-depth analysis and actionable insights for your organization's
financial integrity.</p>
                    <div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Risk Management</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Risk management services focus on identifying, assessing, and mitigating
financial risks that could impact an individuals or organization&#39;s financial stability and
success. This includes implementing risk management strategies related to market risk,
credit risk, operational risk, and regulatory compliance.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Cash Flow Management</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Cash flow management services focus on optimizing the inflow
and outflow of cash within an organization to ensure sufficient liquidity for ongoing
operations and strategic initiatives. This may involve cash flow analysis, forecasting, and
implementing strategies to improve cash flow efficiency.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>



        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Tax Education and Training</h5>
                    <p class="text-muted mt-3 mb-0 service-p">As a part of our contribution to the Clients and the community
at large we work to offer educational programs and training sessions to help individuals,
businesses and society at large understand tax laws, regulations, and best practices. This
empowers each stakeholder to make informed decisions and manage their tax affairs more
effectively.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
               
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Investment Management</h5>
                    <p class="text-muted mt-3 mb-0 service-p">Investment management services involve managing investment
portfolios on behalf of individuals, families, or institutions. This includes asset allocation,
investment selection, portfolio monitoring, and performance reporting to help clients
achieve their investment objectives while managing risk.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>




        <div class="col-lg-4 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Business Stock Listing & Initial Public Offering (IPO)</h5>
                    <p class="text-muted mt-3 mb-0 service-p">The services offered include legal and
financial advisory services to help the company comply with regulatory requirements,
prepare necessary documentation, and navigate the process of listing on the stock
exchange. The nature of services offered for an IPO includes financial advisory, valuation,
legal, and regulatory compliance services to help the company prepare for and execute the
offering successfully.</p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
               
            </div>
        </div>


    </div>

</div>



<div class="container">


    
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="section-title mb-4 pb-2">
            <h2 class="display-7 fw-bold mb-4">Business Advisory Services</h2>
            <h6 class="text-muted text-danger mb-0">We are giving you perfect solutions with our proficient services.</h6>
            </div>
        </div>
    </div>

    <div class="row">

    <div class="col-lg-5 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                <h5 class="title">Business & Project Consultancy Services</h5>
                    <p class="text-muted mt-3 mb-0 service-p">The Consultancy offers services to small business
owners and entrepreneurs on various financial matters, such as feasibility analysis, project
conceptualization, project funding, business planning, cash flow management, financing
options etc. The services offered help business owners make sound financial decisions to
support business growth, profitability, and long-term success.</p>
                    <div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="col-lg-6 col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                <h5 class="title">Startup Incubation Service</h5>
                    <p class="text-muted mt-3 mb-0 service-p" >Under Business Incubation Services we offer entire range of financial management and regulatory compliance services so that the
founders can singularly focus on the success of their startup venture. It is a single window
offering which covers : <br/>
<span>Business development / Business strategy / Marketing / Strategic Tie-ups / Cashflow Design &amp; Management / 
Financial Planning &amp; Management / Funding / Intellectual Property Rights / Legal Compliances / Operations &amp; Management
</span></p>
<div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                    <Link to="/team">Learn More</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>

    </div>

</div>

            
{/* <section class="py-3 py-md-5 py-xl-8">
<div class="p-3 py-xl-8">
    <div class="row justify-content-center">


        <div class="col-12">
            <div class="section-title mb-4 pb-2">
            <h3 class="fs-2 text-secondary mb-3 mb-xl-4 text-uppercase">What We Do?</h3>
            <h2 class="display-7 fw-bold mb-4">Business Advisory Services</h2>
               <h6 class="text-muted text-danger mb-0">We are giving you perfect solutions with our proficient services.</h6>
            </div>
        </div>
        
    </div>

    <div class="row">

    <div class="col-lg col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Business & Project Consultancy Services</h5>
                    <p class="text-muted mt-3 mb-0">The Consultancy offers services to small business
owners and entrepreneurs on various financial matters, such as feasibility analysis, project
conceptualization, project funding, business planning, cash flow management, financing
options etc. The services offered help business owners make sound financial decisions to
support business growth, profitability, and long-term success.</p>
                    <div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                  Learn More
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="col-lg col-md-6 col-12 pt-2">
            <div class="card service-wrapper rounded border-0 shadow p-4">
                <div class="content mt-2">
                    <h5 class="title">Startup Incubation Service</h5>
                    <p class="text-muted mt-3 mb-0">Under Business Incubation Services the Consultancy offering
complete range of financial management and regulatory compliance services so that the
founders can singularly focus on the success of their startup venture. It is a single window
offering which covers : <br/>
<span>Business development / Business strategy / Marketing / Strategic Tie-ups / Cashflow Design &amp; Management / 
Financial Planning &amp; Management / Funding / Intellectual Property Rights / Legal Compliances / Operations &amp; Management
</span></p>
                    <div class="mt-3">
                    <a href="#!" class="fw-bold text-decoration-none link-primary">
                  Learn More
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                  </svg>
                </a>
                    </div>
                </div>
                
            </div>
        </div>
</div>



</div>
</section> */}







<HomeBanner />
{/* <FAQ /> */}
        <Footer />
        </div>
    )
};

export default Service;