import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import "../Style/Team.css";
import ankit from "../Resources/ankit.jpg";
import arpan from "../Resources/arpan.jpg";
import khushboo from "../Resources/khushboo.jpg";
import kulTaran from "../Resources/kulTaran.jpg";
import ravi from "../Resources/ravi.jpg";
import saurabh from "../Resources/saurabh.jpg";
import sreemannarayana from "../Resources/sreemannarayana.jpg";
import tanuj from "../Resources/tanuj.jpg";
import tarun from "../Resources/tarun.jpg";
import vipan from "../Resources/vipan.jpg";
import naveen2 from "../Resources/naveen2.jpg";
import vikas2 from "../Resources/vikas2.jpg";
import kunal2 from "../Resources/kunal2.jpg";
import mayank2 from "../Resources/mayank2.jpg";
import sidhharth2 from "../Resources/sidhharth2.jpg";
import rejevmohan from "../Resources/rejevmohan.jpg";
import emailjs from "@emailjs/browser";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PrivacyPolicy from "../Components/PrivacyPolicy";

const Team = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [name, setName] = useState("");
  const [selectedUrgency, setSelectedUrgency] = useState("");
  const navigate = useNavigate();
  const [termsChecked, setTermsChecked] = useState(false);

  const handleButtonClick = (event) => {
    const clickedName = event.target
      .closest(".team_card-content")
      .querySelector(".name").textContent;
    setName(clickedName);
  };

  const [formData, setFormData] = useState({
    u_name: "",
    u_email: "",
    u_phone: "",
    u_message: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'file' ? (files ? files[0] : null) : value,
    });
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      formData.u_name.trim() !== "" &&
      formData.u_email.trim() !== "" &&
      formData.u_message.trim() !== "" &&
      formData.u_phone.trim() !== "" &&
      termsChecked
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData, termsChecked]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!termsChecked) {
      alert("Please agree to the terms and conditions.");
      return;
    }

    
  try {
    let fileUrl = null;

    if (formData.file) {
      const fileRef = ref(storage, `files/${formData.file.name}`);
      await uploadBytes(fileRef, formData.file);
      fileUrl = await getDownloadURL(fileRef);
    }

    

const templateParams = {
  u_name: formData.u_name,
  u_email: formData.u_email,
  u_phone: formData.u_phone,
  u_message: formData.u_message,
  consultant_name: name,
  fileUrl: fileUrl,
  urgency: selectedUrgency,
};

emailjs
.send(
  'service_66ghhmx',
'template_02evjuj',
templateParams,
'IUdgUmRc43niA9p88'
)
.then((result) => {
  // alert(result.text);
  alert('Consultation booked with ' + name + ' is confirmed!');
  navigate('/thank-you');
  document.getElementById('exampleModalToggle').click();
})
      .catch((error) => {
        alert(error.text);
      });
  } catch (error) {
    alert('Error uploading file: ' + error.message);
  }
  };

  return (
    <>
      <div className="teamPage">
        <div className="team_heading mb-5">
          <h1>OUR ASSOCIATES</h1>
        </div>

        <div className="team_card">
          <div class="teamCard">
            <div className="team_img">
              <img src={kulTaran} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Kul Taran Singh Anand</div>
              <div class="qualification">
              Bachelor of Commerce (B.Com), Master of Commerce (M.Com), MBA in Finance, Fellow Chartered Accountant (FCA)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
              Global taxation, Off-shore tax planning, M&A,  Financial analysis, Business re-engineering, Process reporting and Family office management
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 40 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          <div class="teamCard">
            <div className="team_img">
              <img src={naveen2} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Naveen Singal</div>
              <div class="qualification">
              Chartered Accountant (CA), Bachelor of Commerce (B.com), Post Graduate Diploma in Management (PGDM), Diploma in International Financial Reporting Standards (ACCA,UK), Registered Valuer and Insolvency Professional, Forensic Accounting and Fraud detection (FAFD)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">Business Valuation and Insolvency Professional Services</div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 30+ Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* rejev mohan */}
          <div class="teamCard">
            <div className="team_img">
              <img alt="ProfileImage" src={rejevmohan} />
            </div>

            <div class="team_card-content">
              <div class="name">Rejev Mohan</div>
              <div class="qualification">
                BA (Economics), Diploma in Civil Engineering (Hons),Diploma in
                Advance System Analysis & Design, Sybase & Case Tools
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">Business Advisory Services</div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 25 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          <div class="teamCard">
            <div className="team_img">
              <img src={vipan} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Vipan Kumar</div>
              <div class="qualification">
              Bachelor of Architecture, Land & Building Valuer - Income Tax Department & IBBI, Chartered Engineer
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">Architectural & Interior Consultant, Property Valuation</div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 35 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          <div class="teamCard">
            <div className="team_img">
              <img src={ankit} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Ankit Garg</div>
              <div class="qualification">
              Bachelor of Commerce (B.com), Chartered Accountant (CA), Forensic Accounting and Fraud Detection (FAFD), Computer Hacking Forensics Investigator (CHFI)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
              Tax assessment and appeals, Comprehensive Tax Planning, forensic audit services
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* khushboo */}
          <div class="teamCard">
            <div className="team_img">
              <img src={khushboo} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Khushboo Garg</div>
              <div class="qualification">
                Bachelor of Business Administration (BBA)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
                Co-founder &amp; Business Advisory Services
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* saurabh */}
          <div class="teamCard">
            <div className="team_img">
              <img src={saurabh} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Saurabh Garg</div>
              <div class="qualification">
                Chartered Accountant (CA), CFA (USA)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
             Forensic audit , statutory audit, compliance 
              and integrity advisory services
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* vikas */}
          <div class="teamCard">
            <div className="team_img">
              <img src={vikas2} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">VIKAS S AGARWAL</div>
              <div class="qualification">Chartered Accountant (CA)</div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
             Debt / Equity / Structured Fund raise.
             Experience in Risk Assessment and Management, Compliance and Equity / Debt / Structured fund raising. He is presently founder of Investment Banking Company and raised equity / debt / structured debt. He is advising new age companies, startups on structured debt raise and advising on growth strategies.
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 28+ Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* ravi */}
          <div class="teamCard">
            <div className="team_img">
              <img src={ravi} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Ravi Pratap</div>
              <div class="qualification">
              Bachelor of Science (B.Sc.), Bachelor of Legislative Law (LLB), Post Graduate Diploma in Law
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
              Tax Litigations, Comprehensive tax planning
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* tanuj */}
          <div class="teamCard">
            <div className="team_img">
              <img src={tanuj} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Tanuj Keswani</div>
              <div class="qualification">
                Bachelor of Commerce (B.com), Chartered Accountant (CA),
                Forensic Accounting and Fraud Detection (FAFD)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">Cash Flow Management</div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* kunal */}
          <div class="teamCard">
            <div className="team_img">
              <img src={kunal2} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Kunal Gaba</div>
              <div class="qualification">
                Bachelor of Commerce (B.com), Chartered Accountant (CA)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
                GST Compliance and Optimization Consulting
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* mayank */}
          <div class="teamCard">
            <div className="team_img">
              <img src={mayank2} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Mayank Dhingra</div>
              <div class="qualification">
                Bachelor of Commerce (B.com), Chartered Accountant (CA)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
                IND AS Implementation and Compliance Consulting
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* sreemann */}
          <div class="teamCard">
            <div className="team_img">
              <img src={sreemannarayana} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Sreemannaaray a Mallela</div>
              <div class="qualification">
              Bachelor of Commerce (B.com), Master of commerce (M.com), Bachelor of Laws (BL), Master of Laws (ML), Chartered Accountant – Intermediate (CA-Inter)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
              Comprehensive Tax Planning and Compliance services. 
              Specialized in FEMA, SEBI, Corporate and Commercial laws, Tax laws including International Taxation. Enrolled with AP(HC) and serves as Legal Counsel for Tax matters in other high courts
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* siddharth */}
          <div class="teamCard">
            <div className="team_img">
              <img src={sidhharth2} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Siddharth</div>
              <div class="qualification">
                Company Secretary, Bachelor of Legislative Law (LLB)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">Corporate Governance Consulting</div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 15 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* arpan */}
          <div class="teamCard">
            <div className="team_img">
              <img src={arpan} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">ARPAN JAIN</div>
              <div class="qualification">
                Chartered Accountant (CA), Bachelor of Commerce Honours (B.com
                Hons.)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">Tax Assessment and Analysis</div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 10 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>

          {/* tarun */}
          <div class="teamCard">
            <div className="team_img">
              <img src={tarun} alt="ProfileImage" />
            </div>

            <div class="team_card-content">
              <div class="name">Tarun Pruthi</div>
              <div class="qualification">
                Chartered Accountant (CA), Bachelor of Commerce (B.com), Diploma
                in Information System Audit, Forensic Accounting and Fraud
                Detection (FAFD)
              </div>
              <small className="fw-bold">Specialization :</small>
              <div class="specialization">
                Fraud Risk Assessment and Investigative Support
              </div>
              <div className=" mb-2">
                <small className="fw-bold">
                  Experience: <span> 10 Years</span>
                </small>
              </div>
              <button
                class="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={handleButtonClick}
              >
                BOOK CONSULTATION
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* booking popup modal */}
      <form className="form" name="enq" onSubmit={handleSubmit}>
        <div
          className="modal fade"
          id="exampleModalToggle"
          data-bs-backdrop="static"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                  {name}
                </h1>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="mt-3">
                    <h6>Urgency :</h6>
                    <div className="form-check">
                      <input
                        className="form-check-input border border-dark fw-bold border-2"
                        type="radio"
                        name="urgency"
                        id="urgencyLow"
                        value="Low"
                        onChange={(e) => setSelectedUrgency(e.target.value)}
                        checked={selectedUrgency === "Low"}
                      />
                      <label className="form-check-label" htmlFor="urgencyLow">
                        Low
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input border border-dark fw-bold border-2"
                        type="radio"
                        name="urgency"
                        id="flexRadioDefault1"
                        value="Medium"
                        onChange={(e) => setSelectedUrgency(e.target.value)}
                        checked={selectedUrgency === "Medium"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Medium
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input border border-dark fw-bold border-2"
                        type="radio"
                        name="urgency"
                        id="flexRadioDefault2"
                        value="High"
                        onChange={(e) => setSelectedUrgency(e.target.value)}
                        checked={selectedUrgency === "High"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        High
                      </label>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-outline-success"
                      data-bs-target="#exampleModalToggle2"
                      data-bs-toggle="modal"
                    >
                      Next
                    </button>
                    <button
                      className="btn mx-3 btn-outline-danger"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          data-bs-backdrop="static"
          id="exampleModalToggle2"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h5>Client Details</h5>
                <p>Tell us a bit about yourself :</p>
                <div className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="inputName" className="form-label">
                      Name*
                    </label>
                    <input
                      type="name"
                      className="form-control"
                      id="inputName"
                      name="u_name"
                      value={formData.u_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4" className="form-label">
                      Email*
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      name="u_email"
                      value={formData.u_email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputPhone" className="form-label">
                      Phone Number* <small>(with country code)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPhone"
                      name="u_phone"
                      value={formData.u_phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Write a brief note about your query?
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="u_message"
                      value={formData.u_message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-12">
                  <label htmlFor="file" className="form-label">
                      Attach document, if any <small>(Optional)</small>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="inputGroupFile04"
                      aria-describedby="inputGroupFileAddon04"
                      aria-label="Upload"
                      name="file"
                      onChange={handleChange}
                    />
                    {/* Display uploaded files */}
                    <div></div>
                  </div>

                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        name="u_check"
                        checked={termsChecked}
                        onChange={() => setTermsChecked(!termsChecked)}
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                        I agree to the <a href="#!" data-bs-target="#termsandcondition" data-bs-toggle="modal">Terms of Service Offering</a>.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-md-flex justify-content-md-end">
                  <button
                    className="btn mx-3 btn-outline-danger"
                    data-bs-target="#exampleModalToggle"
                    data-bs-toggle="modal"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-success"
                    data-bs-dismiss="modal"
                    disabled={isButtonDisabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="termsandcondition" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="termsandconditionlabel2" tabindex="-1">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      {/* <div class="modal-header" style={{backgroundColor:'#0B401A'}}> */}
        {/* <h1 class="modal-title fs-5 " id="termsandconditionlabel2">Terms of Service Offering</h1> */}
      {/* </div> */}
      <div class="modal-body" style={{marginTop:"-150px"}}>
        <PrivacyPolicy />
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Back</button>
      </div>
    </div>
  </div>
</div>

      </form>
      <Footer />
    </>
  );
};
export default Team;
